import { ISiteMngData } from './types';

const mockData: ISiteMngData[] = [
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GBX9ZV',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Cliniques Universitaires Saint-Luc',
    address: 'Avenue Hippocrate 10;',
    country: 'Belgium',
    zip: '1200',
    latitude: '50.8529343',
    longitude: '4.4520088',
    siteCode: 'BEL003',
    city: 'Bruxelles',
    contactPhone: '+3227642832',
    piName: 'Gimbada Benny Mwenge',
    contactEmail: 'Gimbada Benny Mwenge',
    state: null,
    accountNumber: 'STL',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GBX9Z1',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Private Practice RESPISOM Namur',
    address: 'Chaussée de MArche 569',
    country: 'Belgium',
    zip: '5101',
    latitude: '50.4416504',
    longitude: '4.9061679',
    siteCode: 'BEL001',
    city: 'Namur',
    contactPhone: '+32495502608',
    piName: 'Jean Benoit  Martinot',
    contactEmail: 'Jean Benoit  Martinot',
    state: null,
    accountNumber: null,
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GC2C1H',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'UZA',
    address: 'Drie Eikenstraat 655..',
    country: 'Belgium',
    zip: '2650',
    latitude: '51.15593',
    longitude: '4.423057',
    siteCode: 'BEL002',
    city: 'Edegem',
    contactPhone: '+3238214765',
    piName: 'Ilse  De Volder',
    contactEmail: 'Ilse  De Volder',
    state: null,
    accountNumber: 'PARENT',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GBXA1J',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'CHU de Grenoble - Hôpital Albert Michallon',
    address: 'Boulevard de la Chantourne.',
    country: 'France',
    zip: '38043',
    latitude: '45.1969789',
    longitude: '5.7487296',
    siteCode: 'FRA002',
    city: 'Grenoble',
    contactPhone: '+33476765516',
    piName: 'Jean Louis  Pepin',
    contactEmail: 'Jean Louis  Pepin',
    state: 'Isere',
    accountNumber: 'CIC pluridisciplinaire - unité de pharmacologie',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GC2C49',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Hopital Gui de Chauliac',
    address: '80  Avenue Augustin Fliche',
    country: 'France',
    zip: '34295',
    latitude: '43.6289292',
    longitude: '3.86481',
    siteCode: 'FRA001',
    city: 'Montpellier',
    contactPhone: '+33467337478',
    piName: 'Yves  Dauvilliers',
    contactEmail: 'Yves  Dauvilliers',
    state: 'Herault',
    accountNumber: 'Service de Neurologie',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GB9F8V',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'CHU Nantes - Hôtel Dieu',
    address: '1 place Alexis Ricordeau',
    country: 'France',
    zip: '44093',
    latitude: '47.2122953',
    longitude: '-1.5543947',
    siteCode: 'FRA004',
    city: 'Nantes',
    contactPhone: '+33240165510',
    piName: 'Laurène  Leclair Visonneau',
    contactEmail: 'Laurène  Leclair Visonneau',
    state: 'Loire Atlantique',
    accountNumber: 'Neurologie - Exploration du sommeil',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GC8239',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Hopital Roger Salengro - CHU Lille',
    address: '2 Rue Emile Laine',
    country: 'France',
    zip: '59037',
    latitude: '50.6074008',
    longitude: '3.030883',
    siteCode: 'FRA003',
    city: 'Lille Cedex',
    contactPhone: '+33320446461',
    piName: 'Lise  Lanvin',
    contactEmail: 'Lise  Lanvin',
    state: 'Nord',
    accountNumber: 'service de neurologie D',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GMOHSX',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Rady Childrens Hospital',
    address: "3030 Children's Way",
    country: 'United States',
    zip: '92123',
    latitude: '32.7989113',
    longitude: '-117.1510617',
    siteCode: 'USA036',
    city: 'San Diego',
    contactPhone: '7737029200',
    piName: 'Rakesh  Bhattacharjee',
    contactEmail: 'Rakesh  Bhattacharjee',
    state: 'California',
    accountNumber: '300134142',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GDYK7P',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'OHSU Hospital',
    address: '3181 SW Sam Jackson Park Rd',
    country: 'United States',
    zip: '97239',
    latitude: '45.4990627',
    longitude: '-122.6862985',
    siteCode: 'USA024',
    city: 'Portland',
    contactPhone: '5034944493',
    piName: 'Akram  Khan',
    contactEmail: 'Akram  Khan',
    state: 'Oregon',
    accountNumber: null,
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6H88YAX',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Metro Health Medical Center',
    address: '2500 Metro Health Drive',
    country: 'United States',
    zip: '44109',
    latitude: '41.4607746',
    longitude: '-81.6987862',
    siteCode: 'USA034',
    city: 'Cleveland',
    contactPhone: '2167785864',
    piName: 'John C. Carter',
    contactEmail: 'John C. Carter',
    state: 'Ohio',
    accountNumber: null,
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6H8TPYH',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'University of Arizona',
    address: '1670 E Drachman St.',
    country: 'United States',
    zip: '85721',
    latitude: '32.2389289',
    longitude: '-110.947351',
    siteCode: 'USA033',
    city: 'Tuscon',
    contactPhone: '5206942522',
    piName: 'Sairam  Parthasarathy',
    contactEmail: 'Sairam  Parthasarathy',
    state: 'Arizona',
    accountNumber: '300148958',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GDY06L',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'University of Louisville',
    address: '231 East Chestnut Street, N-97/2 East',
    country: 'United States',
    zip: '40202',
    latitude: '38.2484474',
    longitude: '-85.7496969',
    siteCode: 'USA028',
    city: 'Louisville',
    contactPhone: '5025624294',
    piName: 'Mohamed A Saad',
    contactEmail: 'Mohamed A Saad',
    state: 'Kentucky',
    accountNumber: '300141953',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GDYK39',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Stanford School of Medicine',
    address: '450 Broadway Street M/C 5704',
    country: 'United States',
    zip: '94063',
    latitude: '37.485815',
    longitude: '-122.203104',
    siteCode: 'USA012',
    city: 'Redwood City',
    contactPhone: '6507256517',
    piName: 'Emmanuel  Mignot',
    contactEmail: 'Emmanuel  Mignot',
    state: 'California',
    accountNumber: null,
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6J8JMOQ',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'University Hospitals Cleveland Medical Center',
    address: '11100 Euclid Ave',
    country: 'United States',
    zip: '44106',
    latitude: '41.5061253',
    longitude: '-81.6053845',
    siteCode: 'USA021',
    city: 'Cleveland',
    contactPhone: '2168443267',
    piName: 'Sally  Ibrahim',
    contactEmail: 'Sally  Ibrahim',
    state: 'Ohio',
    accountNumber: 'Dept of Pulmonary, Critical Care and Sleep Medicin',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GDZ7EP',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Meris Clinical Research, LLC',
    address: '311 Noland Drive',
    country: 'United States',
    zip: '33511',
    latitude: '27.9346062',
    longitude: '-82.2895147',
    siteCode: 'USA004',
    city: 'Brandon',
    contactPhone: '8136548100',
    piName: 'Dragos G. Zanchi',
    contactEmail: 'Dragos G. Zanchi',
    state: 'Florida',
    accountNumber: null,
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GDY2K5',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Bryan Medical Center',
    address: '1600 South 48th Street',
    country: 'United States',
    zip: '68506',
    latitude: '40.7964623',
    longitude: '-96.6510634',
    siteCode: 'USA023',
    city: 'Lincoln',
    contactPhone: '4024863410',
    piName: 'John F Trapp',
    contactEmail: 'John F Trapp',
    state: 'Nebraska',
    accountNumber: 'East / PARENT = Bryan Heart',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6SWIC15',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'University of Maryland Medical Center',
    address: '110 S Paca St',
    country: 'United States',
    zip: '21201',
    latitude: '39.287248',
    longitude: '-76.6227238',
    siteCode: 'USA035',
    city: 'Baltimore',
    contactPhone: '4104941662',
    piName: 'Brian J Bohner',
    contactEmail: 'Brian J Bohner',
    state: 'Maryland',
    accountNumber: '300210521',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6V4V9TQ',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Ocean Wellness Clinic',
    address: '18441 NW 2nd Ave, Suite 206',
    country: 'United States',
    zip: '33169',
    latitude: '25.9440175',
    longitude: '-80.2044593',
    siteCode: 'USA032',
    city: 'Miami',
    contactPhone: '7864097699',
    piName: 'Jeremy  Bleicher',
    contactEmail: 'Jeremy  Bleicher',
    state: 'Florida',
    accountNumber: null,
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6WBNBB5',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Serenity Research Center LLC',
    address: '8900 SW 107 AVE SUITE 206',
    country: 'United States',
    zip: '33176',
    latitude: '25.6857959',
    longitude: '-80.3663486',
    siteCode: 'USA031',
    city: 'Miami',
    contactPhone: '3055962080',
    piName: 'Sergio E Jaramillo',
    contactEmail: 'Sergio E Jaramillo',
    state: 'Florida',
    accountNumber: null,
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GF6E8V',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Hospital General de Castellon',
    address: 'Avda Benicasim s/n',
    country: 'Spain',
    zip: '12004',
    latitude: '40.0001161',
    longitude: '-0.0404663',
    siteCode: 'ESP002',
    city: 'Castellon',
    contactPhone: '+34964725000',
    piName: 'Juan Jose  Ortega Albas',
    contactEmail: 'Juan Jose  Ortega Albas',
    state: 'Castellón',
    accountNumber: 'Servicio de Neurofisiología',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GF6FRV',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Hospital Universitario Araba – Sede Santiago',
    address: 'C/ Olaguibel 29',
    country: 'Spain',
    zip: '01004',
    latitude: '42.8466489',
    longitude: '-2.6668274',
    siteCode: 'ESP004',
    city: 'Vitoria',
    contactPhone: '+34945007309',
    piName: 'Ainhoa  Alvarez Ruiz de Larrinaga',
    contactEmail: 'Ainhoa  Alvarez Ruiz de Larrinaga',
    state: 'Alava',
    accountNumber: 'Sleep Unit',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GF6GHP',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Hospital Universitario Clinico San Carlos',
    address: 'Profesor Martin Lagos s/n',
    country: 'Spain',
    zip: '28040',
    latitude: '40.4399297',
    longitude: '-3.7188437',
    siteCode: 'ESP001',
    city: 'Madrid',
    contactPhone: '+34913303272',
    piName: 'Francisco Javier  Martinez Orozco',
    contactEmail: 'Francisco Javier  Martinez Orozco',
    state: 'Madrid',
    accountNumber: 'Neurology Department',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'source',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GF6FQH',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: "Hospital Universitari Vall d'Hebron",
    address: "Passeig Vall d'Hebron 119-129.",
    country: 'Spain',
    zip: '08035',
    latitude: '41.4283018',
    longitude: '2.142488',
    siteCode: 'ESP003',
    city: 'Barcelona',
    contactPhone: '+349327400003576\n000000000',
    piName: 'Odile  Romero Santo Tomas',
    contactEmail: 'Odile  Romero Santo Tomas',
    state: 'Barcelona',
    accountNumber: 'Neurophysiology Dept.',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [],
  },
  {
    source: 'staging',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GBXA2X',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'IRCCS Istituto de  Scienze Neurologiche di Bologna (ISNB)',
    address: 'Via Altura, 3',
    country: 'Italy',
    zip: '40139',
    latitude: '44.463369',
    longitude: '11.3930393',
    siteCode: 'ITA001',
    city: 'Bologna',
    contactPhone: '+39512092950',
    piName: 'Giuseppe  Plazzi',
    contactEmail: 'Giuseppe  Plazzi',
    state: 'Bologna',
    accountNumber: 'Clinica Neurologica',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [
      {
        isSubRow: true,
        source: 'source',
        protocolName: 'JZP258-406',
        ctmsRowId: '1-6GBXA2X',
        language: 'en',
        optedIn: true,
        siteStatus: 'Selected',
        siteName: 'IRCCS Istituto de  Scienze Neurologiche di Bologna (ISNB)',
        address: 'Via Altura, 3',
        country: 'Italy',
        zip: '40139',
        latitude: '44.463369',
        longitude: '11.3930393',
        siteCode: 'ITA001',
        city: 'Bologna',
        contactPhone: '+39512092950',
        piName: 'Giuseppe  Plazzi',
        contactEmail: 'Giuseppe  Plazzi',
        state: 'Bologna',
        accountNumber: 'Clinica Neurologica',
        facilityType: 'Research Facility',
        radiusInMiles: 1000,
        priority: 1,
        threshold: 1000,
        siteDirectedSchedule: true,
        warmTransferNumber: '',
        econsentUrl: null,
        openingHour: null,
        closingHour: null,
        isDefaultVirtualSite: true,
        allowEmails: null,
        allowSmses: null,
        emailCras: null,
        studyStaffEmails: null,
        dataVisualisationsApproved: null,
      },
      {
        isSubRow: true,
        source: 'published',
        protocolName: 'JZP258-406',
        ctmsRowId: '1-6GBXA2X',
        language: 'en',
        optedIn: true,
        siteStatus: 'Selected',
        siteName: 'IRCCS Istituto de  Scienze Neurologiche di Bologna (ISNB)',
        address: 'Via Altura, 3',
        country: 'Italy',
        zip: '40139',
        latitude: '44.463369',
        longitude: '11.3930393',
        siteCode: 'ITA001',
        city: 'Bologna',
        contactPhone: '+39512092950',
        piName: 'Giuseppe  Plazzi',
        contactEmail: 'Giuseppe  Plazzi',
        state: 'Bologna',
        accountNumber: 'Clinica Neurologica',
        facilityType: 'Research Facility',
        radiusInMiles: 1000,
        priority: 1,
        threshold: 1000,
        siteDirectedSchedule: true,
        warmTransferNumber: '',
        econsentUrl: null,
        openingHour: null,
        closingHour: null,
        isDefaultVirtualSite: true,
        allowEmails: null,
        allowSmses: null,
        emailCras: null,
        studyStaffEmails: null,
        dataVisualisationsApproved: null,
      },
    ],
  },
  {
    source: 'staging',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GB9F9Z',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Ospedale San Raffaele (San Raffaele Turro)',
    address: "Via Stamira D'Ancona, 20",
    country: 'Italy',
    zip: '20127',
    latitude: '45.4994304',
    longitude: '9.2301122',
    siteCode: 'ITA002',
    city: 'Milano',
    contactPhone: '+39226433383',
    piName: 'Luigi  Ferini-Strambi',
    contactEmail: 'Luigi  Ferini-Strambi',
    state: 'Milano',
    accountNumber: 'Clinica Neurologica- Div Malattie del Sonno',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [
      {
        isSubRow: true,
        source: 'source',
        protocolName: 'JZP258-406',
        ctmsRowId: '1-6GB9F9Z',
        language: 'en',
        optedIn: true,
        siteStatus: 'Selected',
        siteName: 'Ospedale San Raffaele (San Raffaele Turro)',
        address: "Via Stamira D'Ancona, 20",
        country: 'Italy',
        zip: '20127',
        latitude: '45.4994304',
        longitude: '9.2301122',
        siteCode: 'ITA002',
        city: 'Milano',
        contactPhone: '+39226433383',
        piName: 'Luigi  Ferini-Strambi',
        contactEmail: 'Luigi  Ferini-Strambi',
        state: 'Milano',
        accountNumber: 'Clinica Neurologica- Div Malattie del Sonno',
        facilityType: 'Research Facility',
        radiusInMiles: 1000,
        priority: 1,
        threshold: 1000,
        siteDirectedSchedule: true,
        warmTransferNumber: '',
        econsentUrl: null,
        openingHour: null,
        closingHour: null,
        isDefaultVirtualSite: true,
        allowEmails: null,
        allowSmses: null,
        emailCras: null,
        studyStaffEmails: null,
        dataVisualisationsApproved: null,
      },
      {
        isSubRow: true,
        source: 'published',
        protocolName: 'JZP258-406',
        ctmsRowId: '1-6GB9F9Z',
        language: 'en',
        optedIn: true,
        siteStatus: 'Selected',
        siteName: 'Ospedale San Raffaele (San Raffaele Turro)',
        address: "Via Stamira D'Ancona, 20",
        country: 'Italy',
        zip: '20127',
        latitude: '45.4994304',
        longitude: '9.2301122',
        siteCode: 'ITA002',
        city: 'Milano',
        contactPhone: '+39226433383',
        piName: 'Luigi  Ferini-Strambi',
        contactEmail: 'Luigi  Ferini-Strambi',
        state: 'Milano',
        accountNumber: 'Clinica Neurologica- Div Malattie del Sonno',
        facilityType: 'Research Facility',
        radiusInMiles: 1000,
        priority: 1,
        threshold: 1000,
        siteDirectedSchedule: true,
        warmTransferNumber: '',
        econsentUrl: null,
        openingHour: null,
        closingHour: null,
        isDefaultVirtualSite: true,
        allowEmails: null,
        allowSmses: null,
        emailCras: null,
        studyStaffEmails: null,
        dataVisualisationsApproved: null,
      },
    ],
  },
  {
    source: 'staging',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GB9F9P',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Azienda Ospedaliera Universitaria Policlinico Tor Vergata',
    address: 'Viale Oxford, 81',
    country: 'Italy',
    zip: '00133',
    latitude: '41.8578509',
    longitude: '12.6307675',
    siteCode: 'ITA003',
    city: 'Roma',
    contactPhone: '+39620902107',
    piName: 'Fabio  Placidi',
    contactEmail: 'Fabio  Placidi',
    state: 'Roma',
    accountNumber: 'U.O.C. Neurologia',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [
      {
        isSubRow: true,
        source: 'source',
        protocolName: 'JZP258-406',
        ctmsRowId: '1-6GB9F9P',
        language: 'en',
        optedIn: true,
        siteStatus: 'Selected',
        siteName: 'Azienda Ospedaliera Universitaria Policlinico Tor Vergata',
        address: 'Viale Oxford, 81',
        country: 'Italy',
        zip: '00133',
        latitude: '41.8578509',
        longitude: '12.6307675',
        siteCode: 'ITA003',
        city: 'Roma',
        contactPhone: '+39620902107',
        piName: 'Fabio  Placidi',
        contactEmail: 'Fabio  Placidi',
        state: 'Roma',
        accountNumber: 'U.O.C. Neurologia',
        facilityType: 'Research Facility',
        radiusInMiles: 1000,
        priority: 1,
        threshold: 1000,
        siteDirectedSchedule: true,
        warmTransferNumber: '',
        econsentUrl: null,
        openingHour: null,
        closingHour: null,
        isDefaultVirtualSite: true,
        allowEmails: null,
        allowSmses: null,
        emailCras: null,
        studyStaffEmails: null,
        dataVisualisationsApproved: null,
      },
      {
        isSubRow: true,
        source: 'published',
        protocolName: 'JZP258-406',
        ctmsRowId: '1-6GB9F9P',
        language: 'en',
        optedIn: true,
        siteStatus: 'Selected',
        siteName: 'Azienda Ospedaliera Universitaria Policlinico Tor Vergata',
        address: 'Viale Oxford, 81',
        country: 'Italy',
        zip: '00133',
        latitude: '41.8578509',
        longitude: '12.6307675',
        siteCode: 'ITA003',
        city: 'Roma',
        contactPhone: '+39620902107',
        piName: 'Fabio  Placidi',
        contactEmail: 'Fabio  Placidi',
        state: 'Roma',
        accountNumber: 'U.O.C. Neurologia',
        facilityType: 'Research Facility',
        radiusInMiles: 1000,
        priority: 1,
        threshold: 1000,
        siteDirectedSchedule: true,
        warmTransferNumber: '',
        econsentUrl: null,
        openingHour: null,
        closingHour: null,
        isDefaultVirtualSite: true,
        allowEmails: null,
        allowSmses: null,
        emailCras: null,
        studyStaffEmails: null,
        dataVisualisationsApproved: null,
      },
    ],
  },
  {
    source: 'staging',
    protocolName: 'JZP258-406',
    ctmsRowId: '1-6GF6GB1',
    language: 'en',
    optedIn: true,
    siteStatus: 'Selected',
    siteName: 'Vseobecna fakultni nemocnice v Praze',
    address: 'Katerinska 30',
    country: 'Czech Republic',
    zip: '128 21',
    latitude: '50.074126',
    longitude: '14.4250731',
    siteCode: 'CZE001',
    city: 'Praha 2',
    contactPhone: '+420224965568\n000000000 0000',
    piName: 'Karel  Sonka',
    contactEmail: 'Karel  Sonka',
    state: null,
    accountNumber: 'Dept of Neurologicka klinika 1.LF UK a VFN v Praze',
    facilityType: 'Research Facility',
    radiusInMiles: 1000,
    priority: 1,
    threshold: 1000,
    siteDirectedSchedule: true,
    warmTransferNumber: '',
    econsentUrl: null,
    openingHour: null,
    closingHour: null,
    isDefaultVirtualSite: true,
    allowEmails: null,
    allowSmses: null,
    emailCras: null,
    studyStaffEmails: null,
    dataVisualisationsApproved: null,
    subRows: [
      {
        isSubRow: true,
        source: 'source',
        protocolName: 'JZP258-406',
        ctmsRowId: '1-6GF6GB1',
        language: 'en',
        optedIn: true,
        siteStatus: 'Selected',
        siteName: 'Vseobecna fakultni nemocnice v Praze',
        address: 'Katerinska 30',
        country: 'Czech Republic',
        zip: '128 21',
        latitude: '50.074126',
        longitude: '14.4250731',
        siteCode: 'CZE001',
        city: 'Praha 2',
        contactPhone: '+420224965568\n000000000 0000',
        piName: 'Karel  Sonka',
        contactEmail: 'Karel  Sonka',
        state: null,
        accountNumber: 'Dept of Neurologicka klinika 1.LF UK a VFN v Praze',
        facilityType: 'Research Facility',
        radiusInMiles: 1000,
        priority: 1,
        threshold: 1000,
        siteDirectedSchedule: true,
        warmTransferNumber: '',
        econsentUrl: null,
        openingHour: null,
        closingHour: null,
        isDefaultVirtualSite: true,
        allowEmails: null,
        allowSmses: null,
        emailCras: null,
        studyStaffEmails: null,
        dataVisualisationsApproved: null,
      },
      {
        isSubRow: true,
        source: 'published',
        protocolName: 'JZP258-406',
        ctmsRowId: '1-6GF6GB1',
        language: 'en',
        optedIn: true,
        siteStatus: 'Selected',
        siteName: 'Vseobecna fakultni nemocnice v Praze',
        address: 'Katerinska 30',
        country: 'Czech Republic',
        zip: '128 21',
        latitude: '50.074126',
        longitude: '14.4250731',
        siteCode: 'CZE001',
        city: 'Praha 2',
        contactPhone: '+420224965568\n000000000 0000',
        piName: 'Karel  Sonka',
        contactEmail: 'Karel  Sonka',
        state: null,
        accountNumber: 'Dept of Neurologicka klinika 1.LF UK a VFN v Praze',
        facilityType: 'Research Facility',
        radiusInMiles: 1000,
        priority: 1,
        threshold: 1000,
        siteDirectedSchedule: true,
        warmTransferNumber: '',
        econsentUrl: null,
        openingHour: null,
        closingHour: null,
        isDefaultVirtualSite: true,
        allowEmails: null,
        allowSmses: null,
        emailCras: null,
        studyStaffEmails: null,
        dataVisualisationsApproved: null,
      },
    ],
  },
];

export const smaGlobalSetting = {
  itemName: 'SMA Configuration',
  itemPath: '/sitecore/content/DAP/Global/SMA Configuration/SMA Configuration',
  itemId: 'D41915CAC2F34EF781241128F05806C6',
  eprStudyList: [
    {
      studyItemId: 'f4c787e3-fe05-4211-bd62-1fa238e50a57',
      studyUrl: 'https://cm.dap.localhost/',
      studyName: 'SponsorSite1',
    },
  ],
  taStudyList: [
    {
      studyItemId: '457ba26b-0c30-4ca5-b483-2ce156d0415e',
      studyUrl: 'https://cm.dap.localhost/asthma-studies',
      studyName: 'asthma-studies',
    },
  ],
  fields: [
    {
      fieldValue: 'SiteCode',
      fieldLabel: 'SiteCode',
      fieldKey: 'SiteCode',
      itemId: 'cb0de1ca-7f10-4b78-92cb-75875aa6801f',
      isEditable: false,
    },
    {
      fieldValue: 'SiteName',
      fieldLabel: 'SiteName',
      fieldKey: 'siteName',
      itemId: '25c2502b-8e15-4824-91d6-fd1276dd698c',
      isEditable: true,
    },
    {
      fieldValue: 'PIName',
      fieldLabel: 'PIName',
      fieldKey: 'PIName',
      itemId: 'fde09604-4f0f-4157-8701-b75e2479151e',
      isEditable: true,
    },
    {
      fieldValue: 'Priority',
      fieldLabel: 'Priority',
      fieldKey: 'Priority',
      itemId: 'eca4d9fe-3610-4c03-97d8-ec847364132e',
      isEditable: true,
    },
    {
      fieldValue: 'Radius',
      fieldLabel: 'Radius (In Miles)',
      fieldKey: 'Radius',
      itemId: '2c6de145-c301-4752-b787-f8367b9a07f7',
      isEditable: true,
    },
    {
      fieldValue: 'Threshold',
      fieldLabel: 'Threshold',
      fieldKey: 'Threshold',
      itemId: 'c75e75d4-e3d4-4872-bd86-49e2ca5c9ee4',
      isEditable: true,
    },
    {
      fieldValue: 'Address',
      fieldLabel: 'Address',
      fieldKey: 'address',
      itemId: 'c2db2eac-5dbb-4b95-84c9-128e4dbc4e4d',
      isEditable: true,
    },
    {
      fieldValue: 'City',
      fieldLabel: 'City',
      fieldKey: 'city',
      itemId: 'e02f94cd-18da-4249-9628-cbfd94cdea28',
      isEditable: true,
    },
    {
      fieldValue: 'Site State',
      fieldLabel: 'Site State',
      fieldKey: 'Site State',
      itemId: '6c0ba637-2f89-4bcf-b0f6-9566418fdf73',
      isEditable: true,
    },
    {
      fieldValue: 'ZipCode',
      fieldLabel: 'ZipCode',
      fieldKey: 'ZipCode',
      itemId: '0886c74c-4836-49f8-9f06-30d2701c1e2f',
      isEditable: true,
    },
    {
      fieldValue: 'Country',
      fieldLabel: 'Country',
      fieldKey: 'Country',
      itemId: '93ed35fc-e9b3-46e6-b9bf-a779342a4dee',
      isEditable: true,
    },
    {
      fieldValue: 'Latitude',
      fieldLabel: 'Latitude',
      fieldKey: 'Latitude',
      itemId: 'b86b2578-1011-4bc8-ac61-3630bb6acb22',
      isEditable: true,
    },
    {
      fieldValue: 'Longitude',
      fieldLabel: 'Longitude',
      fieldKey: 'Longitude',
      itemId: '538ef994-4971-43b4-a26a-345804f273de',
      isEditable: false,
    },
    {
      fieldValue: 'Phone',
      fieldLabel: 'Phone',
      fieldKey: 'Phone',
      itemId: '9e33c2a3-e19f-459f-97cd-429d96cc84a4',
      isEditable: false,
    },
    {
      fieldValue: 'Email',
      fieldLabel: 'Email',
      fieldKey: 'Email',
      itemId: '14221dd1-f383-45eb-9e3b-fdebf1f9154d',
      isEditable: false,
    },
    {
      fieldValue: 'WarmTransferNumber',
      fieldLabel: 'WarmTransferNumber',
      fieldKey: 'WarmTransferNumber',
      itemId: '34263496-5bfd-4042-9202-d1010513a10b',
      isEditable: true,
    },
    {
      fieldValue: 'Source',
      fieldLabel: 'Source',
      fieldKey: 'source',
      itemId: '25c2502b-8e15-4824-91d6-fd1276dd698c',
      isEditable: true,
    },
  ],
  siteStatuses: [
    {
      id: 'f50dca07-ca4a-4e76-a06a-5fda4dfe55e0',
      name: 'Active',
      value: null,
      key: null,
      optedIn: true,
    },
    {
      id: 'f79894c4-e929-49ce-8887-f8360fa39939',
      name: 'Closed',
      value: null,
      key: null,
      optedIn: false,
    },
    {
      id: '6464991a-5151-4ec9-bddd-2123bf1087ef',
      name: 'Dropped',
      value: null,
      key: null,
      optedIn: false,
    },
    {
      id: 'e2d6506a-fc4a-431b-a0a3-0eb2ede8d77b',
      name: 'Enrollment Closed',
      value: null,
      key: null,
      optedIn: true,
    },
    {
      id: '7ad85186-7482-4620-aba6-3bdc21a859b4',
      name: 'Enrollment Open',
      value: null,
      key: null,
      optedIn: true,
    },
    {
      id: '4a0599b7-0af5-4c70-9400-a0e6cf490f67',
      name: 'Enrollment on Hold',
      value: null,
      key: null,
      optedIn: true,
    },
    {
      id: '7c02e8ee-809f-4dce-887e-bac307fe241e',
      name: 'Recruiting',
      value: null,
      key: null,
      optedIn: true,
    },
    {
      id: '3cdfa31f-0694-4eae-b3d4-c4caa3a5447f',
      name: 'Selected',
      value: null,
      key: null,
      optedIn: true,
    },
  ],
  smaLanguageList: [
    {
      iso: 'en-US',
      languageName: 'en',
      languageId: 'af584191-45c9-4201-8740-5409f4cf8bdd',
      regionalIsoCode: 'en-US',
    },
    {
      iso: 'en',
      languageName: 'en-US',
      languageId: 'b7be1202-a1a5-4362-8829-c174c6728637',
      regionalIsoCode: 'en-US',
    },
    {
      iso: 'en-GB',
      languageName: 'en-GB',
      languageId: '4f340981-e13c-4d97-9c46-d33b63c15642',
      regionalIsoCode: 'en-GB',
    },
    {
      iso: 'de',
      languageName: 'de-DE',
      languageId: 'f5dcfcd8-296e-4848-9323-4ca2aa1dd601',
      regionalIsoCode: 'de-DE',
    },
  ],
  optedIn: true,
  radius: 100,
  priority: 1,
  threshold: 1000,
  warmTransferNumber: '',
};

export const mockProtocolRes = {
  protocol: [
    {
      itemID: '6A74F996B3F24355B86348D91D714E01',
      itemName: '1002-043',
    },
    {
      itemID: '6782AF1C86754060BB1134F3B6063BC6',
      itemName: 'K-877-302',
    },
    {
      itemID: 'C1BC8C15506C4920BA42A4C11DE42709',
      itemName: 'TEST01',
    },
  ],
};

export const defaultRow = {
  smaRowId: '',
  source: 'staging',
  protocolName: '',
  ctmsRowId: '',
  language: '',
  optedIn: false,
  siteStatus: '',
  siteName: '',
  address: '',
  country: '',
  zip: '',
  latitude: '',
  longitude: '',
  siteCode: '',
  city: '',
  contactPhone: '',
  piName: '',
  contactEmail: null,
  state: '',
  accountNumber: null,
  facilityType: '',
  contactName: '',
  radiusInMiles: null,
  priority: null,
  threshold: null,
  siteDirectedSchedule: false,
  warmTransferNumber: '',
  econsentUrl: null,
  openingHour: null,
  closingHour: null,
  isDefaultVirtualSite: false,
  allowEmails: null,
  allowSmses: null,
  emailCras: null,
  studyStaffEmails: null,
  dataVisualisationsApproved: null,
  daysOfWeek: null,
  isHybridSite: false,
  kiElementsEnabled: false,
  optInDateTime: new Date().toISOString(),
  createdDate: new Date().toISOString(),
  eprId: '',
  protocolItemId: null,
  sitecoreSiteItemId: null,
  sitecoreProtocolItemId: null,
  virtualSiteParticipatingStates: null,
  subRows: [],
  uid: '',
  isSubRow: false,
  isPublished: false,
  siteDetailTarget: 'global',
  EprId: null,
  ProtocolItemId: '',
  UpdatedBy: '',
};
export default mockData;
