/* eslint-disable @typescript-eslint/ban-ts-comment */
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FileCopyIcon from '@mui/icons-material/FileCopy';
// import TranslateIcon from '@mui/icons-material/Translate';
import { useMemo, useRef, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ColumnDef,
  createRow,
  MRT_TableOptions,
  MRT_RowSelectionState,
  MRT_Row,
  MRT_TableInstance,
} from 'material-react-table';
import { EPR_TYPE, ISiteMngData } from './types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import OptInOption from './Tools/OptInOption';
// import TranslateModal from './TranslateModal';
// import translatorService from './microsofttranslator.util';
import FooterActions from './FooterActions/FooterActions';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import { Row } from '@tanstack/table-core';
import Clear from '@mui/icons-material/Clear';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import { defaultRow } from './mockData';
import { isSiteIdAlreadyAvailable } from './helpers';

interface IProps {
  data: ISiteMngData[];
  setData: (newData: ISiteMngData[]) => void;
  setCloneData: (newData: ISiteMngData[]) => void;
  selectedEprName: string;
  role: string;
  setIsLoading: any;
  cloneData: ISiteMngData[];
}
const DiffColor = '#da0000';
const RowColorSource = '#FFE985';
const RowColorSourceHover = '#FFF0AD';
const RowColorPublished = '#79B983';
const RowColorPublishedHover = '#93C89B';
const SOURCE_SMA = 'SMA';
const SOURCE_STAGING = 'staging';

const isSame = (keyName: string, data: ISiteMngData): boolean => {
  if (!data?.subRows || data?.subRows?.length === 0) {
    return true;
  }
  for (const subRow of data?.subRows) {
    // @ts-ignore
    if (data[keyName] !== subRow[keyName]) {
      return false;
    }
  }
  return true;
};

const isEprGlobalPublishRecord = (
  currentRecord: ISiteMngData,
  data: ISiteMngData | any,
  isEprGlobal = false
) => {
  if (!data?.subRows || data?.subRows?.length === 0) return false;
  const eprObj =
    (isEprGlobal && data?.subRows?.find((a: any) => a.eprId !== EPR_TYPE.Global)) || {};
  if (
    !isEmpty(eprObj) &&
    isEprGlobal &&
    currentRecord?.eprId &&
    currentRecord?.eprId === EPR_TYPE.Global &&
    currentRecord?.source === 'published'
  ) {
    return true;
  }
  return false;
};
const isSameRow = (keyName: string, data: ISiteMngData) => {
  if (!data?.subRows || data?.subRows?.length === 0) {
    return true;
  }
  const eprObj = data?.subRows?.find((a) => a.eprId !== EPR_TYPE.Global);
  const globalObj = data?.subRows?.find((a) => a.eprId === EPR_TYPE.Global);
  if (!isEmpty(eprObj) && !isEmpty(globalObj) && keyName) {
    if ((eprObj as any)?.[keyName] !== (globalObj as any)?.[keyName]) {
      return false;
    }
  }
  return true;
};

const isCountryUS = (language: string): boolean => {
  return language === 'en' || language.endsWith('-US');
};

const SitesTable: React.FC<IProps> = (props) => {
  // const [isTranslateOpen, setIsTranslateOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [editedUids, setEditedUids] = useState<string[]>([]);
  const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
  // const refRowIdToTranslate = useRef('');
  const selectedFilters = useSelector((state: any) => state?.sma?.selectedFilters);
  const selectedProtocol = useSelector((state: any) => state?.sma?.selectedProtocol);
  const selectedProtocolItemId = useSelector((state: any) => state?.sma?.selectedProtocolItemId);
  const siteConfigData = useSelector((state: any) => state?.sma?.refreshSiteConfig);
  const eprFieldOptions = useSelector((state: any) => state?.sma?.eprFieldOptions);
  const siteStatusOptions = useSelector((state: any) => state?.sma?.siteStatusOptions);
  const countryStatesOptions = useSelector((state: any) => state?.sma?.countryStatesOptions);
  const fieldOptions = useSelector((state: any) => state?.sma?.fieldOptions);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: `${String(selectedFilters?.EPR)}_${String(selectedFilters?.language)}_${Date.now()}`,
  });

  const fetchVirtualSiteParticipatingStatesName = (virtualSiteParticipatingStates: string) => {
    if (virtualSiteParticipatingStates) {
      const virtualData = virtualSiteParticipatingStates?.split('|').filter((x) => x);
      const virtualName = countryStatesOptions.filter((a) => virtualData.includes(a.value));
      return virtualName?.map((x: any) => x.name)?.join('|') || virtualSiteParticipatingStates;
    }
    return virtualSiteParticipatingStates;
  };

  const handleExportRows = (rows: MRT_Row<any>[]) => {
    const rowsToExport: any[] = [];
    rows.forEach((row) => {
      const clonedRowObj = cloneDeep(row.original);
      clonedRowObj['virtualSiteParticipatingStates'] = fetchVirtualSiteParticipatingStatesName(
        clonedRowObj?.virtualSiteParticipatingStates
      );
      rowsToExport.push(clonedRowObj);
      const subRows = clonedRowObj.subRows;
      if (Array.isArray(subRows) && subRows.length > 0) {
        subRows.forEach((subRowItem: any) => {
          const clonedSubRowObj = cloneDeep(subRowItem);
          clonedSubRowObj['virtualSiteParticipatingStates'] =
            fetchVirtualSiteParticipatingStatesName(
              clonedSubRowObj?.virtualSiteParticipatingStates
            );
          rowsToExport.push(clonedSubRowObj);
        });
      }
    });
    rowsToExport.forEach((item: any) => {
      delete item.uid;
      delete item.subRows;
      delete item.eprId;
      delete item.protocolItemId;
      delete item.sitecoreSiteItemId;
    });
    const csv = generateCsv(csvConfig)(rowsToExport);
    download(csvConfig)(csv);
  };
  // TODO: we used when we need global publish when filter in epr page
  // const fetchTableData = () => {
  //   const cloneData = cloneDeep(props.cloneData);
  //   if (selectedFilters.EPR === EPR_TYPE.Global) return cloneData;
  //   const data: any = [];
  //   let i = 0;
  //   while (i <= cloneData.length) {
  //     const obj = cloneData[i];
  //     const eprRec = obj?.subRows?.filter((a: any) => a.eprId !== EPR_TYPE.Global) || [];
  //     const globalRec = obj?.subRows?.filter((a) => a.eprId === EPR_TYPE.Global) || [];
  //     if (!isEmpty(eprRec)) {
  //       (obj as any)['subRows'] = [...eprRec];
  //       data.push();
  //     } else if (!isEmpty(globalRec)) {
  //       (obj as any)['subRows'] = [...globalRec];
  //     }
  //     data.push(obj);
  //     i++;
  //     if (i === cloneData.length) {
  //       return data;
  //     }
  //   }
  // };

  const onCreateSiteClick = async (table: MRT_TableInstance<ISiteMngData>) => {
    const siteDetail = cloneDeep(defaultRow) as any;
    siteDetail.isSubRow = false;
    siteDetail.language = selectedFilters?.language || 'en';
    siteDetail.protocolName = selectedProtocol || '';
    siteDetail.protocolItemId = selectedProtocolItemId || '';
    siteDetail.siteDetailTarget = props.selectedEprName || '';
    siteDetail.source = SOURCE_STAGING;
    siteDetail.subRows = [];
    siteDetail.smaRowId = uuidv4();
    siteDetail.optedIn = !!siteConfigData.optedIn;
    siteDetail.radiusInMiles = siteConfigData.radius || '';
    siteDetail.priority = siteConfigData.priority || '';
    siteDetail.threshold = siteConfigData.threshold || '';
    siteDetail.isHybridSite = !!siteConfigData.isHybridSite;
    siteDetail.isDefaultVirtualSite = !!siteConfigData.isDefaultVirtualSite;
    siteDetail.kiElementsEnabled = !!siteConfigData.kiElementsEnabled;
    siteDetail.siteDirectedSchedule = !!siteConfigData.siteDirectedSchedule;
    setIsCreateMode(true);
    table.setCreatingRow(createRow(table, siteDetail));
  };
  const handleErrorMessageForMandatoryFields = (values: any) => {
    if (values?.ctmsRowId === '') {
      alert('Please provide SiteID');
      return;
    } else if (values?.siteCode === '') {
      alert('Please provide SiteCode');
      return;
    } else if (values?.siteName === '') {
      alert('Please provide SiteName');
      return;
    }
  };
  const onCloneSave: MRT_TableOptions<ISiteMngData>['onCreatingRowSave'] = async ({
    row,
    values,
    table,
  }) => {
    if (values?.ctmsRowId === '' || values?.siteCode === '' || values?.siteName === '') {
      handleErrorMessageForMandatoryFields(values);
      return;
    }
    let isSameSiteCodeAvailable = [];
    if (values.siteCode) {
      isSameSiteCodeAvailable = props.data.filter((x) => x.siteCode === values.siteCode) as any;
    }
    if (isSameSiteCodeAvailable.length > 0) {
      alert(`SiteCode ${values.siteCode} already exists`);
      return;
    }
    const isSameSiteIdAvailable = props.data.filter((x) => x.ctmsRowId === values.ctmsRowId);
    if (isSameSiteIdAvailable.length > 0) {
      alert(`SiteID ${values.ctmsRowId} already exists in the currently selected protocol`);
      return;
    }
    try {
      const res = await isSiteIdAlreadyAvailable(values.ctmsRowId);
      if (res && res !== '') {
        alert(`SiteID ${values.ctmsRowId} already exists for ${res} protocol`);
        return;
      }
    } catch (_e) {
      return;
    }
    // const newValidationErrors = validateUser(values);
    // if (Object.values(newValidationErrors).some((error) => error)) {
    // setValidationErrors(newValidationErrors);
    // return;
    // }
    // setValidationErrors({});
    // await createUser(values);
    const clonedOriginalRow = cloneDeep(row.original);
    const newRow = {
      ...clonedOriginalRow,
      ...values,
      subRows: [],
      source: isCreateMode ? SOURCE_STAGING : SOURCE_SMA,
      uid: `${clonedOriginalRow.smaRowId}_${isCreateMode ? SOURCE_STAGING : SOURCE_SMA}`,
    };
    setEditedUids((arr) => [...arr, newRow.uid]);
    // @ts-ignore
    props.setData((existingData: any) => [newRow, ...existingData]);
    table.setCreatingRow(null);
    setIsCreateMode(false);
  };

  const handleEditRowSave: MRT_TableOptions<ISiteMngData>['onEditingRowSave'] = async ({
    values,
    table,
    row,
  }) => {
    if (values?.ctmsRowId === '' || values?.siteCode === '' || values?.siteName === '') {
      handleErrorMessageForMandatoryFields(values);
      return;
    }
    const uid = row.id;
    const updatedData = props.data.map((item) => {
      if (item.uid === uid) {
        const newItem = { ...item, ...values };
        item = newItem;
      }
      return item;
    });
    props.setData(updatedData);
    setEditedUids((arr) => [...arr, uid]);
    table.setEditingRow(null); //exit editing mode
  };

  const TableCheckbox = (checkboxProps: any) => {
    const tableData = props.data;
    const uid = checkboxProps.row.id;
    const columnName = checkboxProps.column.id;

    const isParentRow = checkboxProps.row.getCanExpand();
    const currentData = props.data.find((i) => i.uid === uid);
    const fontColor = !isParentRow
      ? 'inherit'
      : isSame(columnName, currentData)
      ? 'inherit'
      : DiffColor;
    return (
      <Checkbox
        sx={{
          color: `${fontColor} !important`,
        }}
        disabled
        checked={checkboxProps.cell.getValue()}
        onChange={(event) => {
          const value = event.target.checked;
          const newData = tableData.map((item) => {
            if (item.uid === uid) {
              // @ts-ignore
              item[columnName] = value;
            }
            return item;
          });
          props.setData(newData);
        }}
      />
    );
  };

  // @ts-ignore
  const EditTableCheckbox = ({ cell, row, column }) => {
    const [checked, setChecked] = useState(cell.getValue());
    return (
      <Checkbox
        checked={checked}
        onChange={(event) => {
          const value = event.target.checked;
          row._valuesCache[column.id] = event.target.checked;
          setChecked(value);
        }}
      />
    );
  };

  // @ts-ignore
  const DefaultCell = ({ cell, row, column }) => {
    const isSubRow = row.original?.isSubRow || false;
    const columnName = column.id;
    const uid = row.id;
    const isParentRow = row.getCanExpand();
    const currentData = props?.data?.find((i) => i.uid === uid) || {};
    const dataList = row.original || {};
    const currentObj = props.cloneData?.find((i) => i.smaRowId === dataList.smaRowId) || {};
    const isChange = isSameRow(columnName, currentObj as any);

    const fontWeight = isSubRow && !isChange && selectedFilters.EPR !== EPR_TYPE.Global ? 700 : 400;

    const fontColor = !isParentRow
      ? 'inherit'
      : // @ts-ignore
      isSame(columnName, currentData)
      ? 'inherit'
      : DiffColor;
    return (
      <Typography
        variant="body"
        sx={{
          color: fontColor,
          fontWeight: fontWeight,
        }}
      >
        {cell.getValue()}
      </Typography>
    );
  };

  // Display Cell for the column virtualSiteParticipatingStates.
  // @ts-ignore
  const vSitePartStatesDispCell = ({ cell, row, column }) => {
    const columnName = column.id;
    const uid = row.id;
    const isParentRow = row.getCanExpand();
    const currentData = props.data.find((i) => i.uid === uid);
    const cellValue = cell.getValue();
    let displayValue = '';

    if (typeof cellValue === 'string') {
      const countryIdMap: Record<string, string> = {};
      countryStatesOptions.forEach((option: { name: string; value: string }) => {
        countryIdMap[option.value] = option.name;
      });
      const selectedCountryIds = cellValue;
      const selectedIdsArray = selectedCountryIds.split('|');
      const finalNames = [];
      for (const id of selectedIdsArray) {
        const countryName = countryIdMap[id];
        if (countryName !== undefined) {
          finalNames.push(countryName);
        }
      }
      const commaSeparatedCountryNames = finalNames.join(',');
      displayValue = commaSeparatedCountryNames;
    }

    const fontColor = !isParentRow
      ? 'inherit'
      : // @ts-ignore
      isSame(columnName, currentData)
      ? 'inherit'
      : DiffColor;
    return (
      <Box
        sx={{
          maxWidth: '200px', // Adjust the width value as per your requirement
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Tooltip title={displayValue}>
          <Typography
            variant="body"
            sx={{
              color: fontColor,
            }}
          >
            {displayValue}
          </Typography>
        </Tooltip>
      </Box>
    );
  };
  // @ts-ignore
  const VSitePartStatesEdit = ({ cell, row, column }) => {
    const getInitialLocalValue = (cellValue: any) => {
      if (Array.isArray(cellValue)) {
        return cellValue;
      } else if (typeof cellValue === 'string') {
        return cellValue.split('|');
      } else if (!cellValue) {
        return [''];
      }
      return [''];
    };

    const [localValue, setLocalValue] = useState<any[]>(() => {
      const actualValue = cell.getValue();
      return getInitialLocalValue(actualValue);
    });
    const options = countryStatesOptions;
    return (
      <Select
        multiple={true}
        // @ts-ignore
        value={localValue}
        onChange={(event: SelectChangeEvent) => {
          const value = event.target.value as any;
          row._valuesCache[column.id] = value.join('|');
          setLocalValue(getInitialLocalValue(value));
        }}
        sx={{
          minWidth: '80%',
          maxWidth: '180px',
        }}
      >
        <MenuItem disabled value="">
          <em>Select</em>
        </MenuItem>
        {options.map((item: any, index: any) => (
          <MenuItem key={index} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  // @ts-ignore
  const DefaultEdit = ({ cell, row, column }) => {
    const [error, setError] = useState('');
    const refValue = useRef(cell.getValue());
    return (
      <TextField
        inputRef={refValue}
        // value={refValue.current}
        defaultValue={cell.getValue()}
        error={!!error}
        helperText={error}
        variant="standard"
        fullWidth
        onBlur={(event) => {
          const value = event.target.value;
          // @ts-ignore
          const regex = new RegExp(column.columnDef.regularExpression);
          const errorMsg = column.columnDef.errorMessage;

          if (!column.columnDef.regularExpression || !errorMsg) {
            // Handle case where validation rules for column.id are not defined
            row._valuesCache[column.id] = event.target.value;
            setError('');
            return;
          }

          if (!value.trim()) {
            setError(errorMsg || 'Cannot be empty');
          } else if (regex && !regex.test(value)) {
            setError(errorMsg || 'Invalid value');
          } else {
            setError('');
            row._valuesCache[column.id] = event.target.value;
          }
        }}
      />
    );
  };

  // @ts-ignore
  const FilterSelectCell = ({ column }) => {
    return (
      <FormControl variant="standard" sx={{ minWidth: 120 }}>
        <Select
          labelId="source-filter"
          id="source-filter"
          size="small"
          value={column.getFilterValue() || ''}
          onChange={(event) => {
            const value = event?.target?.value as string;
            column.setFilterValue(value);
          }}
          sx={{
            '& .MuiInputBase-input ': {
              color: column.getFilterValue() ? 'common.white' : '#747678',
              fontWeight: column.getFilterValue() ? 400 : 700,
            },
            '& .MuiSelect-iconStandard ': { visibility: 'hidden' },
          }}
          endAdornment={
            <IconButton
              size="small"
              onClick={() => {
                tempRef.current = '';
                column.setFilterValue('');
                props.setData(props.cloneData);
              }}
              sx={{ p: 0.5 }}
            >
              <Clear />
            </IconButton>
          }
          displayEmpty
        >
          <MenuItem disabled value="">
            Filter by Source
          </MenuItem>
          <MenuItem value={'staging'}>Staging</MenuItem>
          {selectedFilters.EPR === EPR_TYPE.Global && <MenuItem value={'source'}>Source</MenuItem>}
          <MenuItem value={'published'}>Published</MenuItem>
        </Select>
      </FormControl>
    );
  };

  // @ts-ignore
  const EditSelectCell = ({ cell, row, column }) => {
    const [age, setAge] = useState(() => {
      const actualValue = cell.getValue();
      const isValueInOption = siteStatusOptions.some((option: string) => option === actualValue);
      return isValueInOption ? actualValue : '';
    });
    return (
      <Select
        value={age}
        onChange={(event: SelectChangeEvent) => {
          const value = event.target.value as string;
          row._valuesCache[column.id] = value;
          setAge(value);
        }}
        defaultValue="Select"
        displayEmpty={true}
        sx={{
          minWidth: '80%',
          maxWidth: '180px',
        }}
      >
        <MenuItem disabled value="">
          <em>Select</em>
        </MenuItem>
        {siteStatusOptions.map((item: any, index: any) => {
          return (
            <MenuItem key={index} value={item?.value || item}>
              {item?.name || item}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const tempRef = useRef<string>('');

  const getFilteredValue = (row: Row<ISiteMngData>, id: string, filterValue: any) => {
    if (tempRef.current !== filterValue) {
      tempRef.current = filterValue;
      if (row.index === 0) {
        // TODO: we used when we need global publish when filter in epr page
        // let data = fetchTableData();
        let data = cloneDeep(props.cloneData);
        if (filterValue) {
          props.cloneData.forEach((x, i) => {
            if (x.subRows?.length) {
              data = [...data, ...x.subRows];
              data[i].subRows = [];
            }
          });
        }
        props.setData(data);
      }
    }

    const filters = ['published', 'source'];
    const isPublished =
      filters.includes(row.getValue(id)) &&
      (!row.original?.eprId || row.original?.eprId === EPR_TYPE.Global);

    const isEprPublished =
      row.getValue(id) === filters[0] &&
      row.original?.eprId &&
      row.original?.eprId !== EPR_TYPE.Global;

    return selectedFilters.EPR !== EPR_TYPE.Global
      ? (filterValue === filters[1] && isPublished) ||
          (filterValue === filters[0] && isEprPublished) ||
          (!filters.includes(filterValue) && row.getValue(id) === filterValue)
      : row.getValue(id) === filterValue;
    // TODO: we used when we need global publish when filter in epr page
    // return row.getValue(id) === filterValue;
  };

  const columns = useMemo<MRT_ColumnDef<ISiteMngData>[]>(() => {
    if (!selectedFilters.EPR) {
      return [];
    }
    const columnsArr: MRT_ColumnDef<ISiteMngData>[] = [
      {
        accessorKey: 'protocolName',
        header: 'Protocol',
        enableEditing: isCreateMode || false,
        enableResizing: true,
      },
      {
        accessorKey: 'siteCode',
        header: 'SiteCode',
        enableEditing: isCreateMode || false,
      },
      {
        accessorKey: 'language',
        header: 'Language',
        enableEditing: isCreateMode || false,
      },
      {
        accessorKey: 'ctmsRowId',
        header: 'CTMS Row ID',
        enableEditing: isCreateMode || false,
      },
      {
        accessorKey: 'siteName',
        header: 'Site Name',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      {
        accessorKey: 'siteStatus',
        header: 'Site Status',
        Cell: DefaultCell,
        Edit: EditSelectCell,
      },
      {
        accessorKey: 'optedIn',
        header: 'Opted In',
        enableSorting: false,
        Cell: TableCheckbox,
        Edit: EditTableCheckbox,
        filterVariant: 'select',
        filterSelectOptions: ['true', 'false'],
        filterFn: 'equals',
      },

      { accessorKey: 'address', header: 'Address', Cell: DefaultCell, Edit: DefaultEdit },
      { accessorKey: 'country', header: 'Country', Cell: DefaultCell, Edit: DefaultEdit },
      { accessorKey: 'zip', header: 'Zip', Cell: DefaultCell, Edit: DefaultEdit },
      { accessorKey: 'latitude', header: 'Latitude', Cell: DefaultCell, Edit: DefaultEdit },
      { accessorKey: 'longitude', header: 'Longitude', Cell: DefaultCell, Edit: DefaultEdit },
      { accessorKey: 'city', header: 'City', Cell: DefaultCell, Edit: DefaultEdit },
      {
        accessorKey: 'contactPhone',
        header: 'Contact Phone',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      { accessorKey: 'piName', header: 'piName', Cell: DefaultCell, Edit: DefaultEdit },
      {
        accessorKey: 'contactEmail',
        header: 'Contact Email',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      {
        accessorKey: 'contactName',
        header: 'Contact Name',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      { accessorKey: 'state', header: 'state', Cell: DefaultCell, Edit: DefaultEdit },
      {
        accessorKey: 'accountNumber',
        header: 'Account Number',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      {
        accessorKey: 'facilityType',
        header: 'Facility Type',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      {
        accessorKey: 'radiusInMiles',
        header: 'Radius in Miles',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      { accessorKey: 'priority', header: 'Priority', Cell: DefaultCell, Edit: DefaultEdit },
      { accessorKey: 'threshold', header: 'Threshold', Cell: DefaultCell, Edit: DefaultEdit },
      {
        accessorKey: 'siteDirectedSchedule',
        header: 'Directed Schedule',
        enableSorting: false,
        Cell: TableCheckbox,
        Edit: EditTableCheckbox,
        filterVariant: 'select',
        filterSelectOptions: ['true', 'false'],
        filterFn: 'equals',
      },
      {
        accessorKey: 'warmTransferNumber',
        header: 'Warm Tran No',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      { accessorKey: 'econsentUrl', header: 'Econsent Url', Cell: DefaultCell, Edit: DefaultEdit },
      { accessorKey: 'openingHour', header: 'Opening Hour', Cell: DefaultCell, Edit: DefaultEdit },
      { accessorKey: 'closingHour', header: 'Closing Hour', Cell: DefaultCell, Edit: DefaultEdit },
      {
        accessorKey: 'isDefaultVirtualSite',
        header: 'Default Virtual',
        enableSorting: false,
        Cell: TableCheckbox,
        Edit: EditTableCheckbox,
        filterVariant: 'select',
        filterSelectOptions: ['true', 'false'],
        filterFn: 'equals',
      },
      {
        accessorKey: 'allowEmails',
        header: 'Allow Emails',
        enableSorting: false,
        Cell: TableCheckbox,
        Edit: EditTableCheckbox,
        filterVariant: 'select',
        filterSelectOptions: ['true', 'false'],
        filterFn: 'equals',
      },
      {
        accessorKey: 'allowSmses',
        header: 'Allow SMS',
        enableSorting: false,
        Cell: TableCheckbox,
        Edit: EditTableCheckbox,
        filterVariant: 'select',
        filterSelectOptions: ['true', 'false'],
        filterFn: 'equals',
      },

      { accessorKey: 'emailCras', header: 'Email Cras', Cell: DefaultCell, Edit: DefaultEdit },
      {
        accessorKey: 'studyStaffEmails',
        header: 'Study Staff Emails',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      {
        accessorKey: 'dataVisualisationsApproved',
        header: 'Data Visual Approved',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      {
        accessorKey: 'daysOfWeek',
        header: 'Days of week',
        Cell: DefaultCell,
        Edit: DefaultEdit,
      },
      {
        accessorKey: 'isHybridSite',
        header: 'Hybrid Site',
        Cell: TableCheckbox,
        Edit: EditTableCheckbox,
        filterVariant: 'select',
        filterSelectOptions: ['true', 'false'],
        filterFn: 'equals',
      },
      {
        accessorKey: 'kiElementsEnabled',
        header: 'Ki Element',
        Cell: TableCheckbox,
        Edit: EditTableCheckbox,
        filterVariant: 'select',
        filterSelectOptions: ['true', 'false'],
        filterFn: 'equals',
      },
      {
        accessorKey: 'virtualSiteParticipatingStates',
        header: 'Virtual State',
        Cell: vSitePartStatesDispCell,
        Edit: VSitePartStatesEdit,
      },
      {
        accessorKey: 'source',
        header: 'Source',
        Cell: DefaultCell,
        Edit: DefaultEdit,
        filterFn: (row, id, filterValue) => getFilteredValue(row, id, filterValue),
        Filter: FilterSelectCell,
      },
    ];
    // Column Conditions
    let columnViewSettings: any[] = [];
    if (selectedFilters.EPR === EPR_TYPE.Global && fieldOptions.length > 0) {
      columnViewSettings = fieldOptions;
    } else if (eprFieldOptions?.length > 0) {
      columnViewSettings = eprFieldOptions;
    }
    const fieldKeys = columnViewSettings.map((item: any) => item.fieldKey);
    const filteredColumns: any = [];
    fieldKeys.forEach((key) => {
      const columnItem = columnsArr.find((item) => item.accessorKey === key);
      if (columnItem) {
        filteredColumns.push(columnItem);
      }
    });
    let finalColumns = filteredColumns.map((item: any) => {
      const fieldSetting = columnViewSettings.find(
        (settingItem: any) => settingItem.fieldKey === item.accessorKey
      );
      item.enableEditing = isCreateMode || fieldSetting.isEditable;
      item.header = fieldSetting.fieldLabel;
      // Below are custom fields which are not required for ReactMaterialTable library
      item.isTranslatable = fieldSetting.isTranslatable || false;
      item.regularExpression = fieldSetting.regularExpression || '';
      item.errorMessage = fieldSetting.errorMessage || '';
      return item;
    });
    const hasVirtualSiteParticipatingStates = (): boolean => {
      return finalColumns.some((obj: any) => obj.accessorKey === 'virtualSiteParticipatingStates');
    };
    if (!isCountryUS(selectedFilters.language) && hasVirtualSiteParticipatingStates()) {
      // Remove the obj with accessorKey virtualSiteParticipatingStates from finalColumns
      finalColumns = finalColumns.filter(
        (obj: any) => obj.accessorKey !== 'virtualSiteParticipatingStates'
      );
    }

    // Column Conditions ENDS
    return finalColumns;
  }, [props.data, props.role, selectedFilters.EPR, selectedFilters.language, isCreateMode]);

  const table = useMaterialReactTable({
    columns,
    data: props.data,
    // enableRowSelection: true,
    // enableColumnOrdering: true,
    // enableGlobalFilter: false,
    editDisplayMode: 'row',
    onEditingRowSave: handleEditRowSave,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    enableRowActions: true,
    positionActionsColumn: 'first',
    onCreatingRowSave: onCloneSave,
    onEditingRowCancel: () => setIsCreateMode(false),
    onCreatingRowCancel: () => setIsCreateMode(false),
    getRowId: (row) => row.uid || '',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
        {!values(selectedFilters).some(isEmpty) &&
          selectedProtocol &&
          selectedFilters.EPR === EPR_TYPE.Global && (
            <>
              <Button onClick={() => onCreateSiteClick(table)} startIcon={<AddIcon />}>
                Add Site
              </Button>
            </>
          )}
      </Box>
    ),
    renderBottomToolbarCustomActions: ({ table }) => {
      // <Button
      //   sx={{
      //     marginLeft: '1rem',
      //     marginBlock: '1rem',
      //   }}
      //   variant="contained"
      //   onClick={() => {
      //     // table.setCreatingRow(true); //simplest way to open the create row modal with no default values
      //     //or you can pass in a row object to set default values with the `createRow` helper function
      //     // table.setCreatingRow(createRow(table, createRowData));
      //     table.setCreatingRow(true);
      //   }}
      // >
      //   Create New Site
      // </Button>

      return (
        <Box>
          <OptInOption
            data={props.data}
            setData={props.setData}
            table={table}
            setEditedUids={setEditedUids}
          />
        </Box>
      );
    },
    enableMultiSort: true,
    enableColumnPinning: true,
    // enableColumnResizing: true, // WARNING: Enabling enableColumnResizing causing some issues in action column need to check
    // enableColumnOrdering: true,
    // enableExpandAll: true, //hide expand all double arrow in column header
    enableExpanding: true,
    paginateExpandedRows: false,
    muiTableHeadRowProps: {
      sx: {
        ['.MuiTableCell-head']: {
          paddingBlock: '1rem',
          paddingLeft: '0.9rem',
        },
        ['.Mui-TableHeadCell-Content-Wrapper']: {},
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontWeight: '600',
        fontSize: '1.10rem',
        backgroundColor: '#212529',
        color: 'black !important',
        ['.Mui-TableHeadCell-Content-Labels']: {
          color: '#ffffff !important',
          path: { fill: 'rgba(255, 255, 255 ) !important' },
        },
        ['.Mui-TableHeadCell-Content-Actions']: {
          color: '#ffffff !important',
          path: { fill: 'rgba(255, 255, 255 ) !important' },
        },
        ['input']: {
          color: '#ffffff !important',
          path: { fill: 'rgba(255, 255, 255 ) !important' },
        },
        ['button']: {
          color: '#6E7173 !important',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      const isSubRow = row.original?.isSubRow || false;
      const source = row.original?.source || '';
      const dataList = row.original || {};
      const currentObj = props.data?.find((i) => i.smaRowId === dataList.smaRowId) || {};

      const isEprGlobalPublish =
        (isSubRow &&
          isEprGlobalPublishRecord(
            row.original,
            currentObj,
            selectedFilters.EPR !== EPR_TYPE.Global
          )) ||
        false;
      let hoverColor = '#E8EBED';
      if (source === 'published') {
        hoverColor = RowColorPublishedHover;
      }
      if (source === 'source') {
        hoverColor = RowColorSourceHover;
      }

      return {
        sx: {
          ...(isEprGlobalPublish && { display: 'none' }),
          ['&:hover td']: {
            backgroundColor: `${hoverColor} !important`,
          },
          ['.Mui-selected.Mui-disabled']: {
            opacity: '0.9 !important',
          },
        },
      };
    },
    muiTableBodyCellProps: ({ row }) => {
      const source = row.original?.source || '';
      let rowColor = '#ffffff';
      if (source === 'published') {
        rowColor = RowColorPublished;
      }
      if (source === 'source') {
        rowColor = RowColorSource;
      }
      return {
        sx: {
          fontSize: '1rem',
          // paddingLeft: isSubRow ? '1.5rem' : '0.9rem',
          backgroundColor: rowColor,
        },
      };
    },
    // muiTableBodyRowProps: ({ cell, column, row, table }) => {},
    enableDensityToggle: true,
    enableRowSelection: (row) => {
      const isSubRow = row.original?.isSubRow || false;
      return !isSubRow;
    },
    enableEditing: (row) => {
      const isSubRow = row.original?.isSubRow || false;
      return !isSubRow;
    },
    selectAllMode: 'page',

    initialState: {
      density: 'compact',
      columnPinning: {
        // left: ['protocolName', 'siteCode', 'ctmsRowId'],
        right: ['mrt-row-actions'],
      },
    },
    onRowSelectionChange: (data: any) => {
      if (isCreateMode) {
        return;
      }
      setRowSelection(data);
    },
    state: { rowSelection },
    renderRowActionMenuItems: ({ row, table }) => {
      const clonedOriginal = cloneDeep(row.original);
      clonedOriginal.isSubRow = false;
      clonedOriginal.subRows = [];
      clonedOriginal.smaRowId = uuidv4();
      const menuItems = [
        // table.setEditingRow(row); // event to trigger editing
        <MenuItem
          key="clone"
          onClick={() => {
            console.info('clone');
            setIsCreateMode(true);
            table.setCreatingRow(createRow(table, clonedOriginal));
          }}
        >
          <ListItemIcon>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Clone</ListItemText>
        </MenuItem>,
      ];
      if (
        selectedFilters?.language &&
        !['en', 'en-US', 'en-us'].includes(selectedFilters.language)
      ) {
        // menuItems.push(
        //   <MenuItem
        //     key="translate"
        //     onClick={() => {
        //       console.info('clicked translate for row id', row.id);
        //       refRowIdToTranslate.current = row.id;
        //       closeMenu();
        //       // setIsTranslateOpen(true);
        //     }}
        //   >
        //     <ListItemIcon>
        //       <TranslateIcon fontSize="small" />
        //     </ListItemIcon>
        //     <ListItemText>Translate</ListItemText>
        //   </MenuItem>
        // );
      }
      return menuItems;
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 200, 500],
      showFirstButton: false,
      showLastButton: false,
    },
    meta: {},
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <Box marginTop={'1.5rem'}>
        <FooterActions
          // @ts-ignore
          editedUids={editedUids}
          setEditedUids={setEditedUids}
          data={props.data}
          setData={props.setData}
          setCloneData={props.setCloneData}
          rowSelection={rowSelection as any}
          selectedEprName={props.selectedEprName || ''}
          role={props.role}
          setIsLoading={props.setIsLoading}
          setRowSelection={setRowSelection}
        />
      </Box>
    </>
  );
};

export default SitesTable;
